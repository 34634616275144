<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!data_loaded"></v-skeleton-loader>
    <BaseTable
      ref="table"
      :headers="headers"
      :items="types"
      :status="true"
      :delete_meg="'حذف النوع ؟ '"
      :delete_loader="delete_loader"
      :delete_dialog="delete_dialog"
      @delete_row="delete_type($event)"
      @edit_row="edit_row($event)"
      @close_delete="delete_dialog = false"
      @show_delete="delete_dialog = true"
      v-if="data_loaded"
    ></BaseTable>
    <!--table -->
    <BaseForm
      v-if="data_loaded"
      :title="title"
      :form_obj="form_obj"
      ref="emForm"
      :form_error="alerts.error"
      :form_success="alerts.success"
      :success_text="alerts.success_text"
      :error_text="alerts.error_text"
      @save_form="save_form()"
      :save_loading="save_loading"
      @reset_id="(type_id = null), (title = 'اضافة نوع')"
      @close_snack="close_snack($event)"
    ></BaseForm>
  </v-container>
  <!--/ container -->
</template>

<script>
import BaseTable from "@/components/base/table";
import BaseForm from "@/components/base/form";

export default {
  name: "companies_types",

  data() {
    return {
      delete_dialog: false,
      type_id: null,
      delete_loader: false,
      types: [],
      title: "اضافة نوع",
      data_loaded: false,
      save_loading: false,
      alerts: {
        success: false,
        error: false,
        success_text: "",
        error_text: "",
      },

      headers: [
        {
          value: "type_name",
          text: "الأسم",
          align: "center",
          sortable: false,
        },
        {
          value: "status",
          text: "الحالة",
          align: "center",
          sortable: false,
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
          sortable: false,
        },
      ],

      form_obj: {
        type_name: {
          value: "",
          label: "اسم النوع",
          type: "text",
          is_require: true,
          rules: [(v) => !!v || "حقل مطلوب"],
        },
        status: {
          value: true,
          label: ["فعال", "غير فعال"],
          type: "switch",
        },
      },
    };
  },

  methods: {
    get_types() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "types",
            method: "get",
          },
        })
        .then((res) => {
          this.types = Object.assign([], res.data.data);
          this.data_loaded = true;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    save_form() {
      this.save_loading = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: this.type_id ? `types/${this.type_id}` : `types`,
            method: this.type_id ? "put" : "post",
          },
          data: {
            type_name: this.form_obj.type_name.value,
            status: this.form_obj.status.value ? 1 : 0,
          },
        })
        .then((res) => {
          this.alerts.error = false;
          this.save_loading = false;
          this.alerts.success_text = "تم اضافة النوع بنجاح";
          this.alerts.success = true;
          this.$refs.emForm.toggle_btn_form();
          this.get_types();
        });
    },
    close_snack(ev) {
      if (!ev.prop) {
        this.alerts[ev.type] = false;
      }
    },
    edit_row(ev) {
      this.title = "تعديل النوع";
      Object.keys(ev.item).forEach((e) => {
        if (this.form_obj[e]) {
          this.form_obj[e].value = ev.item[e];
        }
      });
      console.log(this.form_obj);
      this.type_id = ev.item.id;
      this.$refs.emForm.fade_form();
    },
    delete_type(type_id) {
      this.delete_dialog = false;
      if (type_id) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `types/${type_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.alerts.success = true;
            this.alerts.success_text = "تم حذف النوع";
            this.delete_loader = false;
            this.get_types();
            this.delete_dialog = false;
            if (this.$refs.emForm.toggle_form == true) {
              this.$refs.emForm.toggle_btn_form();
            }
          });
      }
    },
  },
  created() {
    this.get_types();
  },
  components: {
    BaseTable,
    BaseForm,
  },
};
</script>
